import * as React from "react";
import { Link } from "gatsby";
import { Container, Nav, Figure } from "react-bootstrap"


import logo from "../img/logo-ozdoken-white.svg";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import twitter from "../img/social/twitter.svg";
import vimeo from "../img/social/vimeo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer background-dark-gray-ozdo has-text-white-ter">
        <div className="content has-text-left background-dark-gray-ozdo has-text-white-ter">
          <div className="container background-dark-gray-ozdo has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <section className="footer-border-ozdo">
                <img
                  src={logo}
                  alt="Kaldi"
                  style={{ width: "20em", height: "4em" }}
                />

              </section>
              <section className="footer-border-ozdo">
              <div className="footer-border-ozdo">
                  <p>ООО "ОЗДОКЕН&nbsp;АГРО"<br />
                  Официальный представитель<br />Özdöken&nbsp;Tarım&nbsp;Makineleri в России<br />
                  Продажа сельскохозяйственной<br />техники и запасных&nbsp;частей</p>
                </div>
              </section>

              <section className="footer-border-ozdo">
              <div className="footer-border-ozdo">
                  <p>410536, Саратовская&nbsp;область,<br />город&nbsp;Саратов,<br />поселок&nbsp;Зоринский,<br />Промузел&nbsp;Зоринский<br />промзона, ком. 21</p>

                </div>
              </section>
              <section className="footer-border-ozdo">
              <div className="footer-border-ozdo">
                  <p>ОГРН 1216400007328<br />ИНН 6432026430<br />КПП 643201001</p>

                </div>
              </section>

            </div>
          </div>
        </div>
        <Container>
          <footer class="background-dark-gray-ozdo navbar-dark fixed-bottom text-center text-white text-small">
            &copy; ООО "Оздокен АГРО", 2021-{new Date().getFullYear()}. Все права защищены.
          </footer>
        </Container>
      </footer>

    );
  }
};

export default Footer;
