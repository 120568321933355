import React from "react";
import { Link } from "gatsby";
import github from "../img/github-icon.svg";
import logo from "../img/logo-ozdoken-white.svg";
import { Container, Nav, Card, CardImg, CardProps, CardImgProps, NavbarBrand, NavItem, NavLink, Row } from "react-bootstrap"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger() {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: "is-active",
          })
          : this.setState({
            navBarActiveClass: "",
          });
      }
    );
  }

  render() {
    return (
      <nav
        className="navbar is-transparent fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container ms-auto">

          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Ozdoken" style={{ height: "100px" }} />
            </Link>
            {/* Hamburger menu */}
            <div className="navbar-end has-text-centered"></div>

          </div>
          <div
              className={`navbar-burger burger ms-auto ${this.state.navBarActiveClass} `}
              data-target="navMenu"
              role="menuitem"
              tabIndex={0}
              onKeyPress={() => this.toggleHamburger()}
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          <div
            id="navMenu "
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end has-text-centered ">
              <Link className="navbar-item" activeClassName="callout" to="/catalog">
                Каталог
              </Link>
              <Link className="navbar-item" activeClassName="callout" to="/parts">
                Запчасти
              </Link>
              <Link className="navbar-item" activeClassName="callout" to="/documents">
                Документы
              </Link>
              <Link className="navbar-item" activeClassName="callout" to="/contact">
                Контакты
              </Link>
            </div>
            <div className="navbar-end has-text-centered ">
              <a className="navbar-item " href="tel:88004440427">8 (800) 444-04-27</a>
            </div>
          </div>
        </div>

      </nav>
    );
  }
};

export default Navbar;
